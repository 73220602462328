import { StorageTypeKeys } from '@seamless/store';
import { OneContextPersistentStore } from '../../../one-context';
import { UnknownAction, Dispatch } from 'redux';
import { isBrowser } from '../../../utils';

enum Actions {
  START_GUIDED_SESSION = 'startGuidedSession',
  UPDATE_GUIDED_SESSION = 'updateGuidedSession',
  END_GUIDED_SESSION = 'endGuidedSession',
  ADD_EVENT = 'addEvent',
}

const PERSISTED_STATE = 'SALES_EXPERT_LOAD_PERSISTED_STATE';

const salesExpertConnectionName = 'SALES_EXPERT_CONNECTION';

interface SalesExpertState {
  isOnBehalfEnabled: boolean; // Providing with information to other apps that an Assisted Sales journey is under progress.
  guidedSession?: {
    id: string; // Unique identifier for the guided session.
    startTimestamp: string; // Timestamp of the start of the guided session. ISO8601 format.
    customer: { firstName: string; lastName: string };
    lead: { reference?: string; id?: string };
    events: GuidedSessionEvent[]; // List of events that happened during the guided session.
  };
}

type SalesExpertConnectionDispatchers = {
  [Actions.START_GUIDED_SESSION]: (payload: { guidedSession: GuidedSessionPayload; tenant: string }) => void; // toggle isOnBehalfEnabled to true and sets guidedSession data
  [Actions.UPDATE_GUIDED_SESSION]: (payload: { guidedSession: Partial<GuidedSessionPayload>; tenant: string }) => void; // To update some data in guidedSession, for example, more lead information when it's available
  [Actions.END_GUIDED_SESSION]: (payload: { tenant: string }) => void; // toggle isOnBehalfEnabled to false and erases guidedSession data
  [Actions.ADD_EVENT]: (payload: { event: AddEventPayload; tenant: string }) => void; // Add a new event to the events list, transforms AddEventPayload into GuidedSessionEvent
};

interface GuidedSessionPayload {
  id: string;
  customer: { firstName: string; lastName: string };
  lead: { reference?: string; id?: string };
}

interface AddEventPayload {
  type: GuidedSessionEventType;
  version: string; // semantic version
  data: Record<string, any>;
}

interface GuidedSessionEvent {
  type: GuidedSessionEventType;
  tenant: string;
  version: string;
  timestamp: string; // ISO8601 format.
  data: Record<string, any>;
}

enum GuidedSessionEventType {
  WL_VEHICLE_ADDED = 'WL_VEHICLE_ADDED', // eqpodc
  WL_VEHICLE_UPDATED = 'WL_VEHICLE_UPDATED', // eqpodc
  WL_VEHICLE_REMOVED = 'WL_VEHICLE_REMOVED', // eqpodc
}

const initialState: SalesExpertState = {
  isOnBehalfEnabled: false,
};

class SalesExpertConnection extends OneContextPersistentStore<
  SalesExpertConnectionDispatchers,
  Partial<SalesExpertState>
> {
  public allowedTenants = ['sem', 'eqpodc'];

  private allowedGuidedSessionEventTypes: Record<string, string[]> = {
    sem: Object.values(GuidedSessionEventType),
    eqpodc: Object.values(GuidedSessionEventType).filter((type) => type.startsWith('WL_')),
  };

  constructor() {
    super(salesExpertConnectionName);
  }
  async onBeforeRegister(dispatch: Dispatch): Promise<void> {
    const action = await this.loadPersistedStateCallback(this.initialState);

    if (action != null) {
      dispatch(action);
    }

    await this.storeState(this.initialState);
  }

  get initialState(): SalesExpertState {
    const namespaceKey = `swsp:${this.name}`;
    const storageValue = isBrowser() ? localStorage.getItem(namespaceKey) : false;

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return initialState;
  }

  get storageType(): StorageTypeKeys {
    return 'local';
  }

  loadPersistedStateCallback(persistedState: SalesExpertState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  async transformToPersistentState(state: Partial<SalesExpertState>) {
    return state;
  }

  private mutateStateIfAllowed(
    state: SalesExpertState,
    newState: SalesExpertState,
    key: string,
    allowedKeys: string[],
  ): SalesExpertState {
    return allowedKeys.includes(key) ? newState : state;
  }

  private guidedSessionStateFactory(guidedSession: GuidedSessionPayload): SalesExpertState['guidedSession'] {
    return {
      startTimestamp: new Date().toISOString(),
      events: [],
      ...guidedSession,
    };
  }

  private guidedSessionEventFactory(event: AddEventPayload, tenant: string): GuidedSessionEvent {
    return {
      tenant,
      timestamp: new Date().toISOString(),
      ...event,
    };
  }

  // State is changed by only allowed tenants
  getReducer() {
    return (state = initialState, action: any) => {
      if (action.type === this.getActionType(Actions.START_GUIDED_SESSION)) {
        const { guidedSession, tenant } = action.payload;
        const guidedSessionState: SalesExpertState['guidedSession'] = this.guidedSessionStateFactory(guidedSession);
        const newState: SalesExpertState = { ...state, guidedSession: guidedSessionState, isOnBehalfEnabled: true };
        return this.mutateStateIfAllowed(state, newState, tenant, ['sem']);
      } else if (action.type === this.getActionType(Actions.UPDATE_GUIDED_SESSION)) {
        const { guidedSession, tenant } = action.payload;
        const newState = { ...state, guidedSession: { ...state.guidedSession, ...guidedSession } } as SalesExpertState;
        return this.mutateStateIfAllowed(state, newState, tenant, ['sem']);
      } else if (action.type === this.getActionType(Actions.END_GUIDED_SESSION)) {
        const { tenant } = action.payload;
        const newState = { ...state, guidedSession: undefined, isOnBehalfEnabled: false };
        return this.mutateStateIfAllowed(state, newState, tenant, ['sem']);
      } else if (action.type === this.getActionType(Actions.ADD_EVENT)) {
        const { event, tenant } = action.payload;
        const eventState: GuidedSessionEvent = this.guidedSessionEventFactory(event, tenant);
        // if no guidedSession don't change state
        if (!state.guidedSession) {
          return state;
        }
        const newState = {
          ...state,
          guidedSession: {
            ...state.guidedSession,
            events: [...state.guidedSession.events, eventState],
          },
        };
        return this.mutateStateIfAllowed(
          state,
          newState,
          event.type,
          this.allowedGuidedSessionEventTypes[tenant] ?? [],
        );
      } else {
        return state;
      }
    };
  }
  public getPublicDispatchers() {
    return {
      startGuidedSession: (context: { guidedSession: GuidedSessionPayload; tenant: string }) => {
        return this.getAction(Actions.START_GUIDED_SESSION, context);
      },
      updateGuidedSession: (context: { guidedSession: Partial<GuidedSessionPayload>; tenant: string }) => {
        return this.getAction(Actions.UPDATE_GUIDED_SESSION, context);
      },
      endGuidedSession: (context: { tenant: string }) => {
        return this.getAction(Actions.END_GUIDED_SESSION, context);
      },
      addEvent: (context: { event: AddEventPayload; tenant: string }) => {
        return this.getAction(Actions.ADD_EVENT, context);
      },
    };
  }
}

export {
  SalesExpertConnection,
  SalesExpertState,
  SalesExpertConnectionDispatchers,
  salesExpertConnectionName,
  AddEventPayload as SalesExpertConnectionAddEventPayload,
};
