export const enum Actions {
  SET_ACTIVE_PROFILE = 'setActiveProfile',
  SET_PROFILE_STATE = 'setProfileState',
}

export const AUTH_CONNECTION_NAME = 'B2XCORE_AUTHENTICATION';
export const B2X_CUSTOMER_BROADCAST_CHANNEL = 'B2X_CUSTOMER_BROADCAST_CHANNEL';

export enum CustomerGroupType {
  B2C_DEFAULT = 'B2C_DEFAULT',
  B2B_DEFAULT = 'B2B_DEFAULT',
}

export type ProfileConnectionDispatchers = {
  [Actions.SET_ACTIVE_PROFILE]: (payload: string) => void;
  [Actions.SET_PROFILE_STATE]: (payload: ProfileState) => void;
};

export interface Profile {
  id: string | null;
  displayName: string | null;
  customerGroupId: string | null;
  market: string | null;
  customerType: string | null;
  businessType: BusinessType;
  meId?: string;
}

export interface Meta {
  status: Status;
  message?: string;
}

export const enum BusinessType {
  B2B = 'B2B',
  B2C = 'B2C',
}

export const enum Status {
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export interface ProfileState {
  activeProfile: Profile;
  availableProfiles: Profile[];
  meta: Meta;
}

export interface ActiveProfilePersistedState {
  profileId: string | null;
  businessType: BusinessType;
}
