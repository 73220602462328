import { StorageTypeKeys } from '@seamless/store';
import { isBrowser } from '../../../utils';
import { OneContextPersistentStore } from '../../../one-context';
import { UnknownAction, Dispatch, Reducer } from 'redux';
import { useSSRContext } from 'vue';

export const dealerContextConnectionName = 'DEALER_CONTEXT_CONNECTION';

const UPDATE_DEALERCONTEXT = 'UPDATE_DEALER_CONTEXT';
const PERSISTED_STATE = 'DEALER_CONTEXT_LOAD_PERSISTED_STATE';

export type DealerContextState = {
  allOutletId?: string;
  outletId?: string;
};

export type DealerContextDispatchers = {
  updateDealerContext: (context: DealerContextState, tenant: string) => any;
};

const hasParams = (urlSearchParams: URLSearchParams) => {
  return (
    urlSearchParams.has('allOutletId') ||
    urlSearchParams.has('outletId') ||
    urlSearchParams.has('gsOutletId') ||
    urlSearchParams.has('allGsOutletIds')
  );
};

const getDealerContextParams = (urlSearchParams: URLSearchParams) => {
  const dealerContextState: DealerContextState = {};

  // Legacy URL Parameters
  if (urlSearchParams.has('allGsOutletIds')) {
    dealerContextState.allOutletId = urlSearchParams.get('allGsOutletIds') as string;
  }

  if (urlSearchParams.has('allOutletId')) {
    dealerContextState.allOutletId = urlSearchParams.get('allOutletId') as string;
  }

  // Legacy URL Parameters
  if (urlSearchParams.has('gsOutletId')) {
    dealerContextState.outletId = urlSearchParams.get('gsOutletId') as string;
  }

  if (urlSearchParams.has('outletId')) {
    dealerContextState.outletId = urlSearchParams.get('outletId') as string;
  }

  return dealerContextState;
};

export class DealerContextConnection extends OneContextPersistentStore<
  DealerContextDispatchers,
  Partial<DealerContextState>
> {
  public allowedTenants = ['seamless'];

  constructor() {
    super(dealerContextConnectionName);
  }

  async onBeforeRegister(dispatch: Dispatch<UnknownAction>): Promise<void> {
    const action = await this.loadPersistedStateCallback(this.initialState);

    if (action != null) {
      dispatch(action);
    }

    await this.storeState(this.initialState);
  }

  get initialState(): DealerContextState {
    if (!isBrowser()) {
      const ctx = useSSRContext();

      return ctx?.AEMContext.dealerContext;
    }

    const topMostWindow = window.top || window;

    if (topMostWindow.aemNamespace?.dealerContext) {
      return {
        allOutletId: topMostWindow.aemNamespace.dealerContext.allOutletId,
        outletId: topMostWindow.aemNamespace.dealerContext.outletId,
      };
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (hasParams(searchParams)) {
      return getDealerContextParams(searchParams);
    }

    const namespaceKey = `${'swsp'}:${this.name}`;

    if (sessionStorage.getItem(namespaceKey)) {
      const persistedState = JSON.parse(sessionStorage.getItem(namespaceKey) as string);

      return persistedState;
    }

    return {};
  }

  get storageType(): StorageTypeKeys {
    return 'session';
  }

  loadPersistedStateCallback(persistedState: DealerContextState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  async transformToPersistentState(state: Partial<DealerContextState>) {
    return state;
  }

  getReducer(): Reducer {
    return (state = this.initialState, action: any) => {
      switch (action.type) {
        case this.getActionType(UPDATE_DEALERCONTEXT):
          return { ...state, ...action.payload };
        default:
          return state;
      }
    };
  }

  public getPublicDispatchers(): DealerContextDispatchers {
    return {
      updateDealerContext: (context: DealerContextState) => {
        return this.getAction(UPDATE_DEALERCONTEXT, context);
      },
    };
  }
}
