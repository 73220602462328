import { StorageTypeKeys } from '@seamless/store';
import { OneContextPersistentStore } from '../../../one-context';
import { UnknownAction, Dispatch } from 'redux';
import { isBrowser } from '../../../utils';
import { ProfileState } from '../profile-connection/types';

export const userVehicleConnectionName = 'USER_VEHICLE_CONNECTION';

export interface UserVehicleState {
  selectedVehicleVin?: string;
}

export type UserVehicleConnectionDispatchers = {
  updateUserVehicle: (context: UserVehicleState, tenant: string) => any;
};

const UPDATE_USER_VEHICLE = 'UPDATE_USER_VEHICLE';
const PERSISTED_STATE = 'USER_VEHICLE_LOAD_PERSISTED_STATE';

const initialState: Partial<UserVehicleState> = {
  selectedVehicleVin: '',
};

export class UserVehicleConnection extends OneContextPersistentStore<
  UserVehicleConnectionDispatchers,
  Partial<UserVehicleState>
> {
  public allowedTenants = ['mmv', 'oab', 'atcscos', 'seamless'];
  private profileId?: string | null;

  constructor() {
    super(userVehicleConnectionName);
    this.profileConnectionSubscribe();
  }

  readonly profileConnectionSubscribe = () => {
    import('@seamless/one-context').then(async (module) => {
      module.subscribe('ProfileConnection', {
        tenant: 'seamless',
        componentName: 'OneContext-UserVehicleConnection',
        callback: (profile: ProfileState) => {
          if (this.profileId !== undefined && this.profileId !== profile.activeProfile.id) {
            module.getDispatchers('seamless', 'UserVehicleConnection').then((dispatchers) => {
              dispatchers.updateUserVehicle({ selectedVehicleVin: '' });
            });
          }

          this.profileId = profile.activeProfile.id;
        },
      });
    });
  };

  async onBeforeRegister(dispatch: Dispatch<UnknownAction>): Promise<void> {
    const action = await this.loadPersistedStateCallback(this.initialState);

    if (action != null) {
      dispatch(action);
    }

    await this.storeState(this.initialState);
  }

  get initialState(): UserVehicleState {
    const namespaceKey = `swsp:${this.name}`;
    const storageValue = isBrowser() ? sessionStorage.getItem(namespaceKey) : false;

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return initialState;
  }

  get storageType(): StorageTypeKeys {
    return 'session';
  }

  loadPersistedStateCallback(persistedState: UserVehicleState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  async transformToPersistentState(state: Partial<UserVehicleState>) {
    return state;
  }

  getReducer() {
    return (state = initialState, action: any) => {
      if (action.type === this.getActionType(UPDATE_USER_VEHICLE)) {
        return { ...state, ...action.payload };
      } else {
        return state;
      }
    };
  }

  public getPublicDispatchers() {
    return {
      updateUserVehicle: (context: UserVehicleState) => {
        return this.getAction(UPDATE_USER_VEHICLE, context);
      },
    };
  }
}
