import { ContextTypes } from '../types';

export enum ContextTypeEnum {
  store = 'store',
  static = 'static',
}
export const contextTypes: ContextTypes[] = [ContextTypeEnum.static, ContextTypeEnum.store];
export const DEFAULT_TIMEOUT = 500;
export const MAX_RETRIES = 10;
export const TENANT = 'seamless';
export const moduleName = 'ONE-CONTEXT';
