import { TENANT } from '../../../constants';
import { OneContextStore } from '../../../one-context';
import { isBrowser } from '../../../utils';
import { ConsentListEvents, UserCentricsConsentState } from './types';

export const consentContextConnectionName = 'CONSENT_CONTEXT_CONNECTION';

export type ConsentContextConnectionDispatchers = {
  updateConsentContext: (context: UserCentricsConsentState, tenant: string) => any;
};

const UPDATE_CONSENT_CONTEXT = 'UPDATE_CONSENT_CONTEXT';

const initialState: Partial<UserCentricsConsentState[]> = [];

export class ConsentContextConnection extends OneContextStore<
  ConsentContextConnectionDispatchers,
  Partial<UserCentricsConsentState[]>
> {
  public allowedTenants = ['seamless'];

  constructor() {
    super(consentContextConnectionName);
    if (isBrowser()) {
      this.addEventListeners();
    }
  }

  get initialState() {
    return initialState;
  }

  getReducer() {
    return (state = initialState, action: any) => {
      if (action.type === this.getActionType(UPDATE_CONSENT_CONTEXT)) {
        return action.payload;
      }

      return state;
    };
  }

  /**
   * Add the event listeners for handling with CMP.
   */
  private addEventListeners(): void {
    window.addEventListener(ConsentListEvents.ucEventsUpdated, this.consentListener, false);
    document.addEventListener(ConsentListEvents.ucConsentsLoaded, this.consentListener, false);
  }

  /**
   * Callback for handling the CMP V2.
   * Picks the event detail consents and adds it to SDS using WKO Manager Connection
   * {@link https://confluence.mercedes-benz.io/pages/viewpage.action?spaceKey=DCMP&title=Migration+guide}
   */
  readonly consentListener = async (event: CustomEvent): Promise<void> => {
    if (event.detail?.consents) {
      // by the time we use `getPublicDispatchers` connection seems to not be initialized and
      // all changes are not propagated to the store.
      // We need to use the `import` in order to get the dispatchers and update the state.
      import('@seamless/one-context').then(async (module) => {
        await module.getDispatchers(TENANT, 'ConsentContextConnection').then((dispatchers) => {
          dispatchers?.updateConsentContext(event.detail.consents, 'seamless');
        });
      });
    }
  };

  public getPublicDispatchers() {
    return {
      updateConsentContext: (context: UserCentricsConsentState) => {
        return this.getAction(UPDATE_CONSENT_CONTEXT, context);
      },
    };
  }
}
