import { SeamlessCoreStore } from '@seamless/store';
import { AllowedConnections, LoggerLevel, OneContextConnectionObject, StateCallback } from '../types';
import { DEFAULT_TIMEOUT, MAX_RETRIES, ContextTypeEnum } from '../constants';
import { initializeLogger } from '@seamless/logger';

export const isBrowser = () => typeof window !== 'undefined';

/**
 * Checks for connection availability on OneContext Connections Map
 * If the connection is not available yet, it waits until the setup is done
 * Triggers store subscription or callback if it is a static connection
 * @param {Object} - Object with OneContext properties
 * @property {Map<string, OneContextConnectionObject>} OneContextConnections - The One Context Connection Map with all connections
 * @property {string} connectionName - The connectionName.
 * @property {string} mappedConnectionName - The mapped connection name.
 * @property {SeamlessCoreStore} storeInstance - The instance of SeamlessCoreStore.
 * @property {StateCallback} stateCallback - The callback function for handling state updates.
 * @returns {void}
 */
export const establishConnectionAndSubscribe = ({
  connectionsMap,
  connectionName,
  subscribedTenant,
  mappedConnectionName,
  storeInstance,
  stateCallback,
  retryCount = 0,
  logger,
}: {
  connectionsMap: Map<string, OneContextConnectionObject>;
  stateCallback: StateCallback;
  storeInstance: SeamlessCoreStore;
  mappedConnectionName: string;
  connectionName: AllowedConnections;
  subscribedTenant: string;
  retryCount?: number;
  logger: ReturnType<typeof initializeLogger>;
}) => {
  const currentConnection = connectionsMap.get(connectionName);
  if (currentConnection) {
    if (currentConnection.type === ContextTypeEnum.store) {
      storeInstance.subscribe(mappedConnectionName, stateCallback);
    } else {
      const context = currentConnection?.context;
      stateCallback(context);
    }
    // logs have overflowed datadog (https://git.i.mercedes-benz.com/orgs/dh-io-seamless/discussions/299),
    // It was suggested to use WARN instead of LOG but it does not really fit the use case here.
    // Since currently we are not using these metrics for anything we will comment it out until we have a use case

    // logSubscription(logger, subscribedTenant, connectionName);
    return;
  }

  if (retryCount < MAX_RETRIES) {
    setTimeout(() => {
      establishConnectionAndSubscribe({
        connectionsMap,
        connectionName,
        subscribedTenant,
        mappedConnectionName,
        storeInstance,
        stateCallback,
        retryCount: retryCount + 1,
        logger,
      });
    }, DEFAULT_TIMEOUT);
  } else {
    logger.warn(`MAX RETRIES EXCEEDED FOR ${connectionName}`);
  }
};

/**
 * Get the log level for DataDog
 * @returns {LoggerLevel} - The log level for DataDog
 */

export const getDataDogLevel = (): Exclude<LoggerLevel, 'warn' | 'debug'> => {
  if (isBrowser()) {
    const topMostWindow = window.top || window;
    const env = topMostWindow.aemNamespace?.pageEnvironmentVariables?.stage;

    if (env !== 'PROD') {
      return 'info';
    }
  }
  return 'error';
};

// logs have overflowed datadog (https://git.i.mercedes-benz.com/orgs/dh-io-seamless/discussions/299),
// It was suggested to use WARN instead of LOG but it does not really fit the use case here.
// Since currently we are not using these metrics for anything we will comment it out until we have a use case

// /**
//  * Log the subscription
//  * @param {ReturnType<typeof initializeLogger>} logger - The logger instance
//  * @param {string} subscribedTenant - The tenant that subscribed
//  * @param {string} subscribedConnection - The connection that was subscribed
//  * @returns {void}
//  */
// const logSubscription = (
//   logger: ReturnType<typeof initializeLogger>,
//   subscribedTenant: string,
//   subscribedConnection: string,
// ) => {
//   // setup context
//   logger.datadogLogger?.setContextProperty('subscribedTenant', subscribedTenant);
//   logger.datadogLogger?.setContextProperty('subscribedConnection', subscribedConnection);
//   // log the subscription
//   logger.log(`Tenant ${subscribedTenant} subscribed to ${subscribedConnection}`);
//   // remove context (we don't need this but since it is not done in PROD lets keep the context clean)
//   logger.datadogLogger?.removeContextProperty('subscribedTenant');
//   logger.datadogLogger?.removeContextProperty('subscribedConnection');
// };
