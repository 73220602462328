import { SeamlessConnection, PersistentConnection } from '@seamless/store';
import { ContextTypeEnum } from './constants';

type OneContextDispatchers = Record<string, (...args: any) => any>;

export abstract class OneContextStore<
  T extends OneContextDispatchers = OneContextDispatchers,
  StateType = any,
> extends SeamlessConnection<T, StateType> {
  abstract allowedTenants: string[];
  public readonly type = ContextTypeEnum.store;
}

export abstract class OneContextPersistentStore<
  T extends OneContextDispatchers = OneContextDispatchers,
  StateType = any,
> extends PersistentConnection<T, StateType> {
  abstract allowedTenants: string[];
  public readonly type = ContextTypeEnum.store;
}

export abstract class OneContextStatic {
  public readonly type = ContextTypeEnum.static;
  abstract getContext: () => Promise<unknown>;
}
