import { OneContextPersistentStore } from '../../../one-context';
import {
  Actions,
  B2X_CUSTOMER_BROADCAST_CHANNEL,
  BusinessType,
  CustomerGroupType,
  ActiveProfilePersistedState,
  Profile,
  type ProfileConnectionDispatchers,
  type ProfileState,
} from './types';
import { StorageTypeKeys } from '@seamless/store';
import { UnknownAction, Dispatch } from 'redux';
import { isBrowser } from '../../../utils';

const PROFILE_CONNECTION_NAME = 'PROFILE_CONNECTION';
const PERSISTED_STATE = 'PROFILE_PERSISTED_STATE';

const initialState = {};

class ProfileConnection extends OneContextPersistentStore<ProfileConnectionDispatchers, Partial<ProfileState>> {
  public allowedTenants = ['iam', 'mhb2b', 'pal'];

  get storageType(): StorageTypeKeys {
    return 'session';
  }

  get namespaceKey(): string {
    return `swsp:${this.name}`;
  }

  get initialState(): Partial<ProfileState> {
    const storageValue = isBrowser() ? sessionStorage.getItem(this.namespaceKey) : false;

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return initialState;
  }

  getReducer() {
    return (state: ProfileState, action: any) => {
      switch (action.type) {
        case this.getActionType(Actions.SET_PROFILE_STATE): {
          const profileState = action.payload;
          return {
            ...state,
            ...profileState,
          };
        }
        case this.getActionType(Actions.SET_ACTIVE_PROFILE): {
          const profileId = action.payload;
          const activeProfile = this.defineActiveProfile(profileId, state.availableProfiles);
          return {
            ...state,
            activeProfile,
          };
        }
        default:
          return state;
      }
    };
  }

  constructor() {
    super(PROFILE_CONNECTION_NAME);
  }

  async onBeforeRegister(dispatch: Dispatch): Promise<void> {
    const storageValue = isBrowser() ? sessionStorage.getItem(this.namespaceKey) : false;

    if (storageValue) {
      const state = JSON.parse(storageValue);

      const action = await this.loadPersistedStateCallback(state);

      if (action != null) {
        dispatch(action);
      }
    }
  }

  async onStateChange(state: ProfileState): Promise<void> {
    await this.persistProfileState(this.namespaceKey, this.transformToPersistentState(state));
    return Promise.resolve();
  }

  loadPersistedStateCallback(persistedState: ProfileState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  transformToPersistentState(state: ProfileState) {
    return state;
  }

  defineActiveProfile(profileId: string, profiles: Profile[]): Profile {
    const firstProfile = profiles.at(0) as Profile;

    if (!firstProfile.id) {
      return firstProfile;
    }

    if (profileId) {
      const foundProfile = profiles.find(
        (profile) => profile.id === profileId || profile.meId === profileId,
      ) as Profile;
      if (foundProfile) {
        return foundProfile;
      }
    }

    const foundFirstB2BProfile = profiles.find((profile) => profile.businessType === BusinessType.B2B) as Profile;

    if (foundFirstB2BProfile) {
      return foundFirstB2BProfile;
    }

    return firstProfile;
  }

  async persistProfileState(key: string, state: ProfileState): Promise<void> {
    if (!isBrowser()) {
      return;
    }
    try {
      const persistedActiveProfile = window.localStorage.getItem(key);
      const persistedProfileState = window.sessionStorage.getItem(key);
      const persistedId = JSON.parse(persistedActiveProfile || '{}').profileId || null;
      const { id, businessType } = state.activeProfile || {};
      const isB2BLoggedIn = businessType === BusinessType.B2B && id !== CustomerGroupType.B2B_DEFAULT;

      const activeProfile: ActiveProfilePersistedState = {
        businessType,
        // Persist only registered user's B2B profile ID to be able to restore the last used one.
        profileId: isB2BLoggedIn ? id : persistedId,
      };

      window.localStorage.setItem(key, JSON.stringify(activeProfile));
      window.sessionStorage.setItem(key, JSON.stringify(state));

      // sync tabs
      if (JSON.stringify(state) !== persistedProfileState) {
        console.info('Sending new state to other tabs');
        const channel = new BroadcastChannel(B2X_CUSTOMER_BROADCAST_CHANNEL);
        channel.postMessage({ key, value: state });
      }
    } catch (error) {
      console.error('Profile Connection PersistProfileState error:', error);
    }
  }

  public getPublicDispatchers(): ProfileConnectionDispatchers {
    return {
      setProfileState: (context: ProfileState) => {
        return this.getAction(Actions.SET_PROFILE_STATE, context);
      },
      setActiveProfile: (context: string) => {
        return this.getAction(Actions.SET_ACTIVE_PROFILE, context);
      },
    };
  }
}

export { ProfileConnection, ProfileState, ProfileConnectionDispatchers, PROFILE_CONNECTION_NAME };
