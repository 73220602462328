import { StorageTypeKeys } from '@seamless/store';
import { isBrowser } from '../../../utils';
import { OneContextPersistentStore } from '../../../one-context';
import { subscribe } from '@seamless/one-context';
import { Dispatch, Reducer, UnknownAction } from 'redux';
import { UserCentricsConsentState } from '../consent-context-connection/types';
import { TENANT } from '../../../constants/index';

export const campaignContextConnectionName = 'CAMPAIGN_CONTEXT_CONNECTION';

const PERSISTED_STATE = 'CAMPAIGN_CONTEXT_LOAD_PERSISTED_STATE';
const CONSENT_NAME = 'Context_Data';
const CONSENT_CONNECTION_NAME = 'ConsentContextConnection';

const initialState: CampaignContextState = {};

export type CampaignContextState = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
  dclid?: string;
  gagcmid?: string;
};

export const urlContext = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid',
  'dclid',
  'gagcmid',
];

export type CampaignContextDispatchers = NonNullable<unknown>;

const hasParams = (urlSearchParams: URLSearchParams) => {
  return urlContext.filter((value) => urlSearchParams.has(value)).length > 0;
};

const getCampaignContextParams = (urlSearchParams: URLSearchParams) => {
  const campaignContextState: CampaignContextState = {};

  urlContext.forEach((value) => {
    if (urlSearchParams.has(value)) {
      campaignContextState[value as keyof CampaignContextState] = urlSearchParams
        .get(value)
        ?.replace(' ', '+') as string;
    }
  });

  return campaignContextState;
};

export class CampaignContextConnection extends OneContextPersistentStore<
  CampaignContextDispatchers,
  Partial<CampaignContextState>
> {
  public allowedTenants = [TENANT];

  constructor() {
    super(campaignContextConnectionName);
  }

  async onBeforeRegister(dispatch: Dispatch<UnknownAction>): Promise<void> {
    subscribe(CONSENT_CONNECTION_NAME, {
      tenant: TENANT,
      componentName: campaignContextConnectionName,
      callback: async (state: UserCentricsConsentState[]): Promise<void> => {
        if (state.find((consent) => consent.name === CONSENT_NAME && consent.enabled)) {
          const afterConsentAction = await this.loadPersistedStateCallback(this.getState());
          dispatch(afterConsentAction);
          await this.storeState(this.getState());
        }
      },
    });
  }

  private getState(): CampaignContextState {
    if (!isBrowser()) {
      return {};
    }
    const searchParams = new URLSearchParams(window.location.search);

    if (hasParams(searchParams)) {
      return getCampaignContextParams(searchParams);
    }

    const namespaceKey = `${'swsp'}:${this.name}`;

    const storageValue = sessionStorage.getItem(namespaceKey);

    if (storageValue) {
      return JSON.parse(storageValue);
    }

    return {};
  }

  get initialState(): CampaignContextState {
    return initialState;
  }

  get storageType(): StorageTypeKeys {
    return 'session';
  }

  loadPersistedStateCallback(persistedState: CampaignContextState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  /* v8 ignore next 3 */
  async transformToPersistentState(state: Partial<CampaignContextState>) {
    return state;
  }

  getReducer(): Reducer {
    return (_, action: any) => {
      if (action.type === this.getActionType(PERSISTED_STATE)) {
        return { ...action.payload };
      }
    };
  }

  public getPublicDispatchers(): CampaignContextDispatchers {
    return {};
  }
}
