import { isBrowser } from '../../../utils';
import { OneContextStore } from '../../../one-context';
import { useSSRContext } from 'vue';
import { PageEnvironmentVariables } from '../../../types';

export const brandContextConnectionName = 'BRAND_CONTEXT_CONNECTION';

export type ComponentsState = { [key: string]: any };

export type SubBrand = 'AMG' | 'MAYBACH';

export interface BrandContextState {
  businessUnit: string;
  subBrand: SubBrand | null;
}

export type BrandContextConnectionDispatchers = {
  updateBrandContext: (context: BrandContextState, tenant: string) => any;
};

const UPDATE_BRAND_CONTEXT = 'UPDATE_BRAND_CONTEXT';

const initialState: Partial<BrandContextState> = {};

export class BrandContextConnection extends OneContextStore<
  BrandContextConnectionDispatchers,
  Partial<BrandContextState>
> {
  public allowedTenants = [];

  private subBrands: SubBrand[] = ['AMG', 'MAYBACH'];

  constructor() {
    super(brandContextConnectionName);
  }

  /**
   * Format the (valid) subBrand
   * if the subBrand is not valid, return null
   * @param {string} subBrand - The subBrand
   * @returns {SubBrand | null} - The formatted subBrand
   */
  private formatSubBrand(subBrand: string): SubBrand | null {
    let finalSubBrand = null;
    this.subBrands.forEach((brand) => {
      if (subBrand.toUpperCase().endsWith(brand)) {
        finalSubBrand = brand;
      }
    });
    return finalSubBrand;
  }

  /**
   * if there is there is a page brand with subBrand set use it
   * otherwise if there is a vehicle set for the page and it only has one subBrand use it
   * otherwise just return null - which should be interpreted as "Mercedes-Benz without any sub brand"
   * @param {PageEnvironmentVariables} environmentVariables - The environment variables
   * @returns {string | string[]} - The subBrand
   */
  private getSubBrand(environmentVariables: PageEnvironmentVariables): SubBrand | null {
    if (environmentVariables?.brand?.subBrand) {
      return this.formatSubBrand(environmentVariables.brand.subBrand);
    } else if (environmentVariables?.vehicleData?.subBrands?.length === 1) {
      return this.formatSubBrand(environmentVariables.vehicleData.subBrands[0]);
    }
    return null;
  }

  public get initialState() {
    if (!isBrowser()) {
      const ctx = useSSRContext();
      return {
        businessUnit: ctx?.AEMContext.pageAEMContext.businessUnit,
        subBrand: this.getSubBrand(ctx?.AEMContext.pageAEMContext),
      };
    }
    /* v8 ignore next 1 */
    const topMostWindow = window.top || window;

    return {
      businessUnit: topMostWindow?.aemNamespace?.pageEnvironmentVariables.businessUnit,
      subBrand: this.getSubBrand(topMostWindow?.aemNamespace?.pageEnvironmentVariables),
    };
  }

  getReducer() {
    return (state = initialState, action: any) => {
      switch (action.type) {
        case this.getActionType(UPDATE_BRAND_CONTEXT):
          return { ...state, ...action.payload };
        /* v8 ignore next 3 */
        default:
          return state;
      }
    };
  }

  public getPublicDispatchers() {
    return {
      updateBrandContext: (context: BrandContextState) => {
        return this.getAction(UPDATE_BRAND_CONTEXT, context);
      },
    };
  }
}
