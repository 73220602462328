import { EnvironmentVariables, PageEnvironmentVariables } from '../../../types';
import { OneContextStatic } from '../../../one-context';
import { isBrowser } from '../../../utils';
import { useSSRContext } from 'vue';

export const staticPageConnectionName = 'PAGE_CONNECTION';

export interface PageEnvironmentConnectionState {
  environmentVariables: EnvironmentVariables;
  pageEnvironmentVariables: PageEnvironmentVariables;
}

export class PageEnvironmentConnection extends OneContextStatic {
  public get initialState() {
    if (!isBrowser()) {
      const ctx = useSSRContext();
      return {
        pageEnvironmentVariables: ctx?.AEMContext.pageAEMContext,
        environmentVariables: ctx?.AEMContext.globalAEMContext,
      };
    }

    const topMostWindow = window.top || window;

    return {
      pageEnvironmentVariables: topMostWindow?.aemNamespace?.pageEnvironmentVariables ?? {},
      environmentVariables: topMostWindow?.aemNamespace?.environmentVariables ?? {},
    };
  }

  public getContext = async () => {
    return this.initialState;
  };
}
