import { StorageTypeKeys } from '@seamless/store';
import { OneContextPersistentStore } from '../../../one-context';
import { UnknownAction } from 'redux';

export const mbScoreConnectionName = 'MBSCORE_CONNECTION';

export type ComponentsState = { [key: string]: any };

export type AffinityType = {
  affinity: string;
  score: number;
};

export interface MBScoreState {
  baumuster: AffinityType[] | null;
  bodytypeId: AffinityType[] | null;
  engineConcept: AffinityType[] | null;
  fuelType: AffinityType[] | null;
  modelSeries: AffinityType[] | null;
  motorizationSegment: AffinityType[] | null;
  priceSegment: AffinityType[] | null;
  purchaseModel: AffinityType[] | null;
  subBrands: AffinityType[] | null;
  testDrive: AffinityType[] | null;
}

export type MBScoreConnectionDispatchers = {
  updateAffinityScore: (context: MBScoreState, tenant: string) => any;
};

const UPDATE_AFFINITY_SCORE = 'UPDATE_AFFINITY_SCORE';
const PERSISTED_STATE = 'MBSCORE_LOAD_PERSISTED_STATE';

const initialState: Partial<MBScoreState> = {};

export class MBScoreConnection extends OneContextPersistentStore<MBScoreConnectionDispatchers, Partial<MBScoreState>> {
  public allowedTenants = ['cuan'];

  constructor() {
    super(mbScoreConnectionName);
  }

  get initialState() {
    return initialState;
  }

  get storageType(): StorageTypeKeys {
    return 'local';
  }

  loadPersistedStateCallback(persistedState: MBScoreState): UnknownAction | Promise<UnknownAction> {
    return this.getAction(PERSISTED_STATE, persistedState);
  }

  async transformToPersistentState(state: Partial<MBScoreState>) {
    return state;
  }

  getReducer() {
    return (state = initialState, action: any) => {
      switch (action.type) {
        case this.getActionType(UPDATE_AFFINITY_SCORE):
          return { ...state, ...action.payload };
        default:
          return state;
      }
    };
  }

  public getPublicDispatchers() {
    return {
      updateAffinityScore: (context: MBScoreState) => {
        return this.getAction(UPDATE_AFFINITY_SCORE, context);
      },
    };
  }
}
