import { setupContext } from '@seamless/one-context';
import { PageEnvironmentConnection } from '../connections/static/page-connection-static';
import { MBScoreConnection } from '../connections/store/mbscore-connection';
import { DealerContextConnection } from 'src/connections/store/dealer-context-connection';
import { BrandContextConnection } from 'src/connections/store/brand-context-connection';
import { UserVehicleConnection } from 'src/connections/store/user-vehicle-context';
import { CampaignContextConnection } from 'src/connections/store/campaign-context-connection';
import { SalesExpertConnection } from 'src/connections/store/sales-expert-connection';
import { ConsentContextConnection } from 'src/connections/store/consent-context-connection';
import { ProfileConnection } from '../connections/store/profile-connection';

(async () => {
  /** -- END OF ONE CONTEXT SDS IMPLEMENTATION */

  // IMPLEMENTATION OF CONSENT CONTEXT CONNECTION
  setupContext({
    name: 'ConsentContextConnection',
    connectionMethod: ConsentContextConnection,
    tenant: 'seamless',
  });
  // IMPLEMENTATION OF PAGE CONNECTION (WINDOW.AEMNAMESPACE)
  setupContext({
    name: 'PageConnection',
    connectionMethod: PageEnvironmentConnection,
    tenant: 'mbmxp',
  });

  // IMPLEMENTATION OF MBSCORE CONNECTION
  setupContext({
    name: 'MBScoreConnection',
    connectionMethod: MBScoreConnection,
    tenant: 'cuan',
  });

  // IMPLEMENTATION OF PROFILE CONNECTION
  setupContext({
    name: 'ProfileConnection',
    connectionMethod: ProfileConnection,
    tenant: 'iam',
  });

  // IMPLEMENTATION OF DEALER CONTEXT CONNECTION
  setupContext({
    name: 'DealerContextConnection',
    connectionMethod: DealerContextConnection,
    tenant: 'seamless',
  });

  // IMPLEMENTATION OF BRAND CONTEXT CONNECTION
  setupContext({
    name: 'BrandContextConnection',
    connectionMethod: BrandContextConnection,
    tenant: 'seamless',
  });

  // IMPLEMENTATION OF USER VEHICLE CONNECTION
  setupContext({
    name: 'UserVehicleConnection',
    connectionMethod: UserVehicleConnection,
    tenant: 'mmv',
  });

  // IMPLEMENTATION OF CAMPAIGN CONTEXT CONNECTION
  setupContext({
    name: 'CampaignContextConnection',
    connectionMethod: CampaignContextConnection,
    tenant: 'seamless',
  });

  // IMPLEMENTATION OF SALES EXPERT CONNECTION
  setupContext({
    name: 'SalesExpertConnection',
    connectionMethod: SalesExpertConnection,
    tenant: 'sem',
  });
})();
